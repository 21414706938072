<template>
  
  <div class="worktime">
    <!-- <div class="worktime__item">
      <p class="worktime__title uppercase">Время работы</p>
    </div> -->
    <div class="worktime__item mb10">
      <p class="worktime__text">ПН-ПТ 09:00 - 17:00</p>
      <p class="worktime__text">СБ-ВС — выходной</p>
    </div>
    <div class="worktime__item mb10">
      <p class="worktime__text">Сегодня {{ $store.state.nowDate.getDate() }} {{ getMonth }} {{ new Date().getFullYear() }}
        г</p>
     
    </div>
    <div class="worktime__item worktime__item--flex mb10">
      <div class="worktime__day" v-for="item, ind in week" :key="ind">
        <SingleDay :item="item" />
      </div>
    </div>
    <div class="worktime__item mb5">
      <div class="worktime__legend worktime__item--flex">
        <div class="worktime__box worktime__box--open"></div>
        <div class="worktime__box-label">Открыто</div>
      </div>
    </div>
    <div class="worktime__item mb5">
      <div class="worktime__legend worktime__item--flex">
        <div class="worktime__box worktime__box--noworktime"></div>
        <div class="worktime__box-label">Закрыто</div>
      </div>
    </div>
    <div class="worktime__item">
      <div class="worktime__legend worktime__item--flex">
        <div class="worktime__box worktime__box--close"></div>
        <div class="worktime__box-label">Выходной</div>
      </div>
    </div>

  </div>
</template>

<script>
import SingleDay from './components/SingleDay.vue'
export default {
  name: 'App',

  components: {
    SingleDay
  },
  data() {
    return {
      date: new Date(),
      week: this.$store.state.workWeek
    }
  },
  computed: {

    getDate() {
      return new Intl.DateTimeFormat('lt-LT').format(this.date)
    },

    getDay() {
      // день недели число
      return this.date.getDay()
    },
    getMonth() {
      const monthNumber = this.date.getMonth()
      let month = ''
      monthNumber === 0 ? month = 'Январь' :
        monthNumber === 1 ? month = 'Февраль' :
          monthNumber === 2 ? month = 'Март' :
            monthNumber === 3 ? month = 'Апрель' :
              monthNumber === 4 ? month = 'Май' :
                monthNumber === 5 ? month = 'Июнь' :
                  monthNumber === 6 ? month = 'Июль' :
                    monthNumber === 7 ? month = 'Август' :
                      monthNumber === 8 ? month = 'Сентябрь' :
                        monthNumber === 9 ? month = 'Октябрь' :
                          monthNumber === 10 ? month = 'Ноябрь' :
                            monthNumber === 11 ? month = 'Декабрь' :
                              false
      return month
    },
    getTime() {
      let formatter = new Intl.DateTimeFormat("ru", {
        hour: "numeric",
        minute: "numeric"
      });
      return formatter.format(this.date)
    },

  },
  methods: {

  },
  mounted() {
    this.$store.commit('setDatesForWorkWeek')
    this.$store.commit('setHolidaysForWorkWeek')
  }
}
</script>

<style lang="scss">
#app-worktime {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.worktime {
  max-width: 250px;
  // margin: 0 auto;
  height: 200px;
}

.worktime__title {
  text-transform: uppercase;
}

.worktime__item--flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.worktime__text {
  text-align: left;
  margin: 0;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.worktime__box {
  width: 25px;
  height: 15px;
}

.worktime__box--open {
  background-color: lime;
}

.worktime__box--close {
  background-color: #e21414
}
.worktime__box--noworktime{
  background-color: #1417e2
}

.uppercase {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}
</style>
