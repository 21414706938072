import { createStore } from 'vuex'
import json from '@/base/dates.json'

export default createStore({
  state: {
    //текущая дата
    nowDate: new Date(),
    // текущий день 0-6
    nowDay: new Date().getDay(),
    // текущее время 00:00
    nowTime: getTime(),
    // рабочее время
    workTime: {
      start: '09:00',
      end: '17:00',
    },
    // обеденный перерыв (не учитывается)
    launchTime: {
      start: '13:00',
      end: '14:00',
    },
    // массив с датами праздников и сб, вс
    holidays: json.holidays,
    // формируемый объект для вывода на страницу
    workWeek: getWeek(),
  },
  getters: {},
  mutations: {
    setDatesForWorkWeek(state) {
      // заполнение объекта workWeek датами
      // let currentDate = state.nowDate
      let currentDate = new Date()

      //форматирование даты и получение нужной даты от текущей (currentDate) на days дней от нее плюс 1 (state.nowDay + 1) (т.к. массив дней у меня начинается с понедельника). Запись в объект workWeek (от текущей даты отняли номер дня недели + 1, это будет дата для [1] индекса объекта workWeek, затем в цикле добавили увеличивающуюся на 1 дату в остальные ключи) . date - только дата - для компонента SingleDate

      if (state.nowDay === 0) {
        state.workWeek[0].fulldate = new Intl.DateTimeFormat('lt-LT').format(
          currentDate.setDate(currentDate.getDate() - 6),
        )
      } else {
        state.workWeek[0].fulldate = new Intl.DateTimeFormat('lt-LT').format(
          currentDate.setDate(currentDate.getDate() - state.nowDay + 1),
        )
      }

      state.workWeek[0].date = +state.workWeek[0].fulldate.match(/\d{2}$/)[0]

      for (let i = 1; i <= 6; i++) {
        state.workWeek[i].fulldate = new Intl.DateTimeFormat('lt-LT').format(
          currentDate.setDate(currentDate.getDate() + 1),
        )
        state.workWeek[i].date = +state.workWeek[i].fulldate.match(/\d{2}$/)[0]
      }
      
    },
    setHolidaysForWorkWeek(state) {
      // при совпадении даты в ключе объекта workWeek с датой из массива holidays
      // присвоим ключу isHoliday = true
      for (let key in state.workWeek) {
        state.holidays.includes(state.workWeek[key].fulldate)
          ? (state.workWeek[key].isHoliday = true)
          : false
      }
      // console.log(state.workWeek)
    },
  },
  actions: {},
  modules: {},
})

function getWeek() {
  return {
    0: {
      label: 'Пн',
      date: '',
      isHoliday: false,
      noWorkTime: false,
    },
    1: {
      label: 'Вт',
      date: '',
      isHoliday: false,
      noWorkTime: false,
    },
    2: {
      label: 'Ср',
      date: '',
      isHoliday: false,
      noWorkTime: false,
    },
    3: {
      label: 'Чт',
      date: '',
      isHoliday: false,
      noWorkTime: false,
    },
    4: {
      label: 'Пт',
      date: '',
      isHoliday: false,
      noWorkTime: false,
    },
    5: {
      label: 'Сб',
      date: '',
      isHoliday: true,
      noWorkTime: true,
    },
    6: {
      label: 'Вс',
      date: '',
      isHoliday: true,
      noWorkTime: true,
    },
  }
}
function getTime() {
  let formatter = new Intl.DateTimeFormat('ru', {
    hour: 'numeric',
    minute: 'numeric',
  })
  return formatter.format(new Date())
}
