<template>
    <div class="worktime__day-label">
        <p class="worktime__text worktime__text--label">{{ item.label }}</p>
    </div>
    <div class="worktime__day-date" :class="holidayClass">
        {{ item.date }}
    </div>
</template>

<script>
export default {
    name: 'SingleDay',
    props: {
        item: {
            type: Object
        }
    },
    computed: {
        getTime() {
            let formatter = new Intl.DateTimeFormat("ru", {
                hour: "numeric",
                minute: "numeric"
            });
            return formatter.format(new Date())
        },
        currentWorkTime() {
            let res1 = new Intl.Collator().compare(this.$store.state.workTime.start, this.getTime)
            let res2 = new Intl.Collator().compare(this.$store.state.workTime.end, this.getTime)
            return res1 === -1 && res2 === 1 ? true : false

        },
        holidayClass() {
            return {
                'holiday': this.item.isHoliday,
                'now-worktime' : this.$store.state.nowDate.getDate() == this.item.date && this.currentWorkTime === true && !this.item.isHoliday,
                'now-date': this.$store.state.nowDate.getDate() == this.item.date,
                'no-work-now': this.$store.state.nowDate.getDate() == this.item.date && this.currentWorkTime === false && !this.item.isHoliday,
            }
        },

    },

}
</script>

<style lang="scss" scoped>
.worktime__day-date {
    width: 30px;
    font-size: 12px;
    margin-top: 3px;
    padding: 3px 5px;
    background: grey;
    color: white;
}

.now-worktime {
    background-color: lime;
    color: black;
}

.holiday {
    background-color: #e21414;
}

.no-work-now {
    background-color: #1417e2;
}

.now-date {
    animation: .5s jump ease infinite alternate;
}

@keyframes jump {
    0% {
        transform: scale(1);
        box-shadow: 0 1px 2px rgba(0, 0, 0, .75);
    }

    100% {
        transform: scale(1.1);
        box-shadow: 0 4px 10px rgba(0, 0, 0, .3);
    }
}

.worktime__text--label {
    text-align: center;
}</style>